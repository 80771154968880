import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Search from "../components/UI/Search";
import classes from "./listview.module.css";
import editIcon from "../assets/icons/edit.png";
import deleteIcon from "../assets/icons/delete.png";
import {
  callApi,
  callDownloadApi,
  callEncryptedApi,
} from "../utilities/utilities";
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import dayjs from "dayjs";

const Listview = () => {
  const [jnumbers, setJnumber] = useState([]);
  const [searchData, setSearchData] = useState([]);

  let navigate = useNavigate();
  const routeChange = (id) => {
    let path = `update/${id}`;
    navigate(path);
  };

  const fetchJnumbers = () => {
    let callApiResponse = callApi(
      "/Stage/read_manifest ",
      {},
      { "Content-Type": "application/json" },
      "GET"
    );
    callApiResponse
      .then((response) => {
        setJnumber(response.data.Rows);
        setSearchData(response.data.Rows);
        if (response.status !== 200) {
          alert(`${response.data.message}`)
          throw new Error(response.data.message)
        }
      })
      .catch((error) => {
        console.log(error);
      })
      // .finally(
      //   axios.get("https://jsonplaceholder.typicode.com/users/").then((res) => {
      //     const jnumber = res.data;
      //     setJnumber(jnumber);
      //     setSearchData(jnumber);
      //   })
      // );
  };
  useEffect(() => {
    fetchJnumbers();
  }, []);

  const onSearchHandler = (searchInput = "") => {
    if (
      searchInput === "undefined" ||
      searchInput == null ||
      searchInput == ""
    ) {
      setSearchData([])
      setSearchData(jnumbers);
    } else {
      const searchResult = jnumbers.filter((jnumber) => {
        return (
          jnumber.jnumber.toLowerCase().indexOf(searchInput.toLowerCase()) >= 0
        );
      });
      setSearchData(searchResult);
    }
  };

  useEffect(() => {
    onSearchHandler();
  }, []);

  const DeleteHandler = (jnumber) => {
    if(window.confirm('Delete the item?')){
      callApi(
        `/Stage/delete_manifest`,
        { jnumber: jnumber },
        { "Content-Type": "application/json" },
        "POST"
      ).then((response) => {
        // alert("Jnumber deleted!");
        window.location.reload();
        if(response.status !== 200) {
          alert(`${response.data.message}`);
          throw new Error(response.data.message)
        }
        alert(`${response.data.message}`);
      }).catch((e) => {
        console.log(e);
      });}
  };

  const DownloadHandler = () => {
    callDownloadApi(
      "https://4ymy564mal.execute-api.us-east-1.amazonaws.com/Stage/download_manifest",
      {},
      { "Content-Type": "application/json" },
      "GET"
    ).then((response) => {
      if(response.status !== 200) {
        alert(`${response.data.message}`);
        throw new Error(response.data.message)
      }
    }).catch((e) => {
      console.log(e);
    });
  };

  const EncryptedHandler = () => {
    callDownloadApi(
      "https://4ymy564mal.execute-api.us-east-1.amazonaws.com/Stage/encrypt_manifest ",
      {},
      { "Content-Type": "application/json" },
      "GET"
    ).then((response) => {
      if(response.status !== 200) {
        alert(`${response.data.message}`);
        throw new Error(response.data.message)
      }
    }).catch((e) => {
      console.log(e);
    });
  };

  return (
    <div className={classes.ListView}>
      <h2>Contents of Manifest</h2>
      <Search jnumber={jnumbers} searchHandler={onSearchHandler}></Search>
      <div className={classes.downloadDiv}>
        <div className={classes.download}>
          <Button onClick={DownloadHandler}>Download Manifest File</Button>
          <Button onClick={EncryptedHandler}>
            Download Encrypted Manifest File
          </Button>
        </div>
      </div>
      <table className={classes.listTable}>
        <tr>
          <th>Jnumber</th>
          <th>Feature name</th>
          <th>Feature version</th>
          <th>Customer no</th>
          <th>Activation date</th>
          <th>Expiration date</th>
          <th>Revoked</th>
          <th>Update</th>
          <th>Delete</th>
        </tr>
        {searchData.map((jnumber) => {
          return (
            <tr key={jnumber.jnumber}>
              <td>{jnumber.jnumber}</td>
              <td>{jnumber.feature_name}</td>
              <td>{jnumber.feature_version}</td>
              <td>{jnumber.customer_id}</td>
              <td>{dayjs(jnumber.activation_date).format('MM-DD-YYYY')}</td>
              <td>{dayjs(jnumber.expiration_date).format('MM-DD-YYYY')}</td>
              <td>{jnumber.revoked}</td>
              <td>
                <div className={classes.iconDiv}>
                  <button
                    className={classes.iconButton}
                    onClick={() => {
                      routeChange(jnumber.jnumber);
                    }}
                  >
                    <img src={editIcon} className={classes.icons} />
                  </button>
                </div>
              </td>
              <td>
                <button
                  className={classes.iconButton}
                  onClick={() => {
                    DeleteHandler(jnumber.jnumber);
                  }}
                >
                  <img src={deleteIcon} className={classes.icons} />
                </button>
              </td>
            </tr>
          );
        })}
      </table>
      {/* <div className={classes.footer}>.</div> */}
    </div>
  );
};
export default Listview;
